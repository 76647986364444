var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel container"},[_c('HomeLink'),_c('div',{staticClass:"wrapper__title"},[_vm._v(_vm._s(_vm.$t("title")))]),_c('div',{staticClass:"travel__title"},[_vm._v(_vm._s(_vm.$t("oimoTashTitle")))]),_c('div',[_vm._v(_vm._s(_vm.$t("oimoTashDesc")))]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"travel__images"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('br'),_c('div',{staticClass:"travel__title"},[_vm._v(_vm._s(_vm.$t("skyfallTitle")))]),_c('div',[_vm._v(_vm._s(_vm.$t("skyfallDesc")))]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"travel__images"},[_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)]),_c('br'),_c('div',{staticClass:"travel__title"},[_vm._v(_vm._s(_vm.$t("contactInfo")))]),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/1.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/2.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/3.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/4.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/5.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/6.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/7.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/8.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/oimophotos/9.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/skyfall/1.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/skyfall/2.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/skyfall/3.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/skyfall/4.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://orient.smng.kg/uploads/skyfall/5.jpeg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel__contact"},[_c('span',[_vm._v("E-mail")]),_c('a',{attrs:{"href":"mailto:oimotash.kg@gmail.com "}},[_vm._v("oimotash.kg@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel__contact"},[_c('span',[_vm._v("Telegram")]),_c('a',{attrs:{"target":"_blank","href":"https://t.me/oimotash"}},[_vm._v(" +996555432444 (@oimotash) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel__contact"},[_c('span',[_vm._v("Whatsapp")]),_c('a',{attrs:{"target":"_blank","href":"https://wa.me/+996555432444"}},[_vm._v("+996555432444")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel__contact"},[_c('span',[_vm._v("Instagram")]),_c('a',{attrs:{"target":"_blank","href":"https://instagram.com/orient_travel_kg"}},[_vm._v(" @orient_travel_kg ")]),_c('a',{attrs:{"target":"_blank","href":"https://instagram.com/oimotashkg"}},[_vm._v(" @oimotashkg ")]),_c('a',{attrs:{"target":"_blank","href":"https://instagram.com/skyfall_kg"}},[_vm._v(" @skyfall_kg ")])])
}]

export { render, staticRenderFns }