<template>

  <div class="accordion">

    <div @click="toggle" class="accordion__title">{{ title }}</div>

    <div v-if="openedId === item.id" class="accordion__body" v-html="body">

    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    openedId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["currentLanguage"]),
    title() {
      return this.currentLanguage === "ru"
        ? this.item.title_ru
        : this.item.title_en || this.item.title_ru;
    },
    body() {
      return this.currentLanguage === "ru"
        ? this.item.body_ru
        : this.item.body_en || this.item.body_ru;
    },
  },
  methods: {
    toggle() {
      this.$emit("toggleService", this.item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  margin-bottom: 5px;

  &__title {
    background: #595d64;
    color: white;
    padding: 5px 10px;
    /* border-radius: 4px; */
    cursor: pointer;
    border-left: 5px solid #53284f;
  }

  &__body {
    margin-top: 10px;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }
}
</style>

