<template>
  <div class="restaurant wrapper">
    <HomeLink />
    <div class="wrapper__title">{{ $t("title") }}</div>
    <FoodMenuItem
      v-for="(item, index) in normalizedItems"
      :key="item.id"
      :item="item"
      :index="index"
      type="things"
      @increaseQty="increaseQty"
      @discreaseQty="discreaseQty"
    />
    <div v-if="currentLanguage === 'ru'">
    Для более подробной информации пожалуйста свяжитесь с ресепшн.
    </div>
    <div v-else>
    For more information, please contact the reception.
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import views from "@/mixins/views";
import HomeLink from "@/components/common/HomeLink";
export default {
  mixins: [views],
  components: {
    HomeLink,
  },
  computed: {
    ...mapState(["currentLanguage"]),
  },
  async created() {
    try {
      await this.$store.dispatch("getItems", "things");
    } catch (error) {
      console.log(error);
    }
  },
  metaInfo() {
    return { title: this.$t("title") };
  },
  i18n: {
    messages: {
      ru: {
        title: "Предметы по заказу",
      },
      en: {
        title: "Request Items",
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
