<template>
  <div class="testimonial wrapper">
    <HomeLink />
    <div class="wrapper__title">{{ $t("title") }}</div>
    <template v-if="!success">
      <p class="testimonial__desc">
        {{ $t("description") }}
      </p>
      <hr />
      <div class="form-body">
        <div class="testimonial__input">
          <div class="question">{{ $t("question1") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-1-1"
                value="2"
                v-model="question1"
              />
              <label for="question-1-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-1-2"
                value="3"
                v-model="question1"
              />
              <label for="question-1-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-1-3"
                value="4"
                v-model="question1"
              />
              <label for="question-1-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-1-4"
                value="5"
                v-model="question1"
              />
              <label for="question-1-4">5</label>
            </div>
          </div>
        </div>
        <div class="testimonial__input">
          <div class="question">{{ $t("question2") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-2-1"
                value="2"
                v-model="question2"
              />
              <label for="question-2-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-2-2"
                value="3"
                v-model="question2"
              />
              <label for="question-2-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-1-3"
                value="4"
                v-model="question2"
              />
              <label for="question-2-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-1-4"
                value="5"
                v-model="question2"
              />
              <label for="question-2-4">5</label>
            </div>
          </div>
        </div>
        <div class="testimonial__input">
          <div class="question">{{ $t("question3") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-3-1"
                value="2"
                v-model="question3"
              />
              <label for="question-3-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-3-2"
                value="3"
                v-model="question3"
              />
              <label for="question-3-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-3-3"
                value="4"
                v-model="question3"
              />
              <label for="question-3-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-3-4"
                value="5"
                v-model="question3"
              />
              <label for="question-3-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question4") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-4-1"
                value="2"
                v-model="question4"
              />
              <label for="question-4-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-4-2"
                value="3"
                v-model="question4"
              />
              <label for="question-4-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-4-3"
                value="4"
                v-model="question4"
              />
              <label for="question-4-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-4-4"
                value="5"
                v-model="question4"
              />
              <label for="question-4-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question5") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-5-1"
                value="2"
                v-model="question5"
              />
              <label for="question-5-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-5-2"
                value="3"
                v-model="question5"
              />
              <label for="question-5-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-5-3"
                value="4"
                v-model="question5"
              />
              <label for="question-5-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-5-4"
                value="5"
                v-model="question5"
              />
              <label for="question-5-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question6") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-6-1"
                value="2"
                v-model="question6"
              />
              <label for="question-6-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-6-2"
                value="3"
                v-model="question6"
              />
              <label for="question-6-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-6-3"
                value="4"
                v-model="question6"
              />
              <label for="question-6-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-6-4"
                value="5"
                v-model="question6"
              />
              <label for="question-6-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question7") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-7-1"
                value="2"
                v-model="question7"
              />
              <label for="question-7-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-7-2"
                value="3"
                v-model="question7"
              />
              <label for="question-7-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-7-3"
                value="4"
                v-model="question7"
              />
              <label for="question-7-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-7-4"
                value="5"
                v-model="question7"
              />
              <label for="question-7-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question8") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-8-1"
                value="2"
                v-model="question8"
              />
              <label for="question-8-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-8-2"
                value="3"
                v-model="question8"
              />
              <label for="question-8-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-8-3"
                value="4"
                v-model="question8"
              />
              <label for="question-8-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-8-4"
                value="5"
                v-model="question8"
              />
              <label for="question-8-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question9") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-9-1"
                value="2"
                v-model="question9"
              />
              <label for="question-9-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-9-2"
                value="3"
                v-model="question9"
              />
              <label for="question-9-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-9-3"
                value="4"
                v-model="question9"
              />
              <label for="question-9-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-9-4"
                value="5"
                v-model="question9"
              />
              <label for="question-9-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <div class="question">{{ $t("question10") }}</div>
          <div class="answers">
            <div class="answer">
              <input
                type="radio"
                id="question-10-1"
                value="2"
                v-model="question10"
              />
              <label for="question-10-1">2</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-10-2"
                value="3"
                v-model="question10"
              />
              <label for="question-10-2">3</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-10-3"
                value="4"
                v-model="question10"
              />
              <label for="question-10-3">4</label>
            </div>
            <div class="answer">
              <input
                type="radio"
                id="question-10-4"
                value="5"
                v-model="question10"
              />
              <label for="question-10-4">5</label>
            </div>
          </div>
        </div>

        <div class="testimonial__input">
          <label>{{ $t("question11") }}</label>
          <textarea v-model="body"></textarea>
          <div
            v-if="$v.body.$dirty && !$v.body.required"
            class="testimonial__input-error"
          >
            {{ $t("requiredField") }}
          </div>
        </div>
        <div
          v-if="
            ($v.question1.$dirty && !$v.question1.required) ||
              ($v.question2.$dirty && !$v.question2.required) ||
              ($v.question3.$dirty && !$v.question3.required) ||
              ($v.question4.$dirty && !$v.question4.required) ||
              ($v.question5.$dirty && !$v.question5.required) ||
              ($v.question6.$dirty && !$v.question6.required) ||
              ($v.question7.$dirty && !$v.question7.required) ||
              ($v.question8.$dirty && !$v.question8.required) ||
              ($v.question9.$dirty && !$v.question9.required) ||
              ($v.question10.$dirty && !$v.question10.required)
          "
          class="error"
        >
          {{ $t("answer_to_all") }}
        </div>
        <button class="testimonial__btn" @click="submit">
          {{ $t("send") }}
        </button>
      </div>
    </template>
    <template v-else>
      <div class="success-sent">
        {{ $t("successSent") }}
        <router-link :to="{ name: 'home' }">{{ $t("gotoHome") }}</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import HomeLink from "@/components/common/HomeLink"
export default {
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question8: "",
      question9: "",
      question10: "",
      body: "",
      success: false,
    }
  },
  i18n: {
    messages: {
      ru: {
        title: "Мини опрос гостей отеля",
        description:
          "Просим Вас уделить 5 минут вашего внимания и заполнить анкету. Критерии оценки по шкале баллов 2 - неуд., 3 - удовл.,4 - хорошо, 5 - отлично. Ваши ответы помогут нам исправить  наши ошибки и в будущем стать лучше!",
        name: "Ваше имя (необязательно)",
        message: "Ваше сообщение",
        requiredField: "Это поле обязательно к заполнению.",
        send: "Отправить",
        successSent: "Спасибо! Вы успешно отправили отзыв.",
        gotoHome: "На главную",
        question1:
          "Что вы думаете о чистоте, удобстве столов и кресел в нашем ресторане?",
        question2: "Как вы оцениваете качество наших блюд?",
        question3:
          "Вас устраивает ассортимент предлагаемых блюд и размер порций?",
        question4: "Качество и разнообразие шведского стола",
        question5: "Соответствуют ли приготовленные блюда описанию в меню?",
        question6: "Все ли выбранные вами блюда были доступны к заказу?",
        question7:
          "Устраивает ли вас время подачи нашего завтрака (7.00-10.00)",
        question8:
          "Оцените соотношение цены и качества ресторанного обслуживания.?",
        question9: "Чистота в номерах и в местах  общего пользования",
        question10: "Коммуникативность и приветливость ресепшена",
        question11: "Ваши рекомендации по улучшению сервиса в нашем отеле?",
        answer_to_all:
          "Чтобы отправить отзыв, необходимо ответить на все вопросы.",
      },
      en: {
        title: "Mini survey of hotel guests",
        description:
          "Please take 5 minutes of your time and fill out the questionnaire. Evaluation criteria on a scale of points 2 - unsuccessful, 3 - satisfactory, 4 - good, 5 - excellent Your answers will help us correct our mistakes (shortcomings) and become better in the future!",
        name: "Your name (not required)",
        message: "Your message",
        requiredField: "This field is required.",
        send: "Send",
        successSent: "Thank you! You have successfuly sent your feedback.",
        gotoHome: "Goto Home",
        question1:
          "What do you think about the cleanliness, comfort of tables and chairs in our restaurant??",
        question2: "How would you rate the quality of our dishes?",
        question3:
          "Are you satisfied with the range of dishes offered and the size of the portions?",
        question4: "Rate the quality and variety of the buffet",
        question5:
          "Do the prepared dishes correspond to the description on the menu?",
        question6: "Were all the dishes you chose available for order?",
        question7:
          "Are you satisfied with the time of serving our breakfast (7.00-10.00)",
        question8: "How would you rate the guest’s service at the restaurant?",
        question9:
          "How would you rate the order (cleanliness) in the common areas?",
        question10: "Communication and friendliness of the reception",
        question11: "Any suggestions for improving the service in our hotel?",
        answer_to_all: "All questions must be answered to submit feedback.",
      },
    },
  },
  components: {
    HomeLink,
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      await this.$store.dispatch("sendTestimonial", {
        question1: this.question1,
        question2: this.question2,
        question3: this.question3,
        question4: this.question4,
        question5: this.question5,
        question6: this.question6,
        question7: this.question7,
        question8: this.question8,
        question9: this.question9,
        question10: this.question10,
        body: this.body,
      })

      this.success = true
    },
  },
  validations: {
    body: { required },
    question1: { required },
    question2: { required },
    question3: { required },
    question4: { required },
    question5: { required },
    question6: { required },
    question7: { required },
    question8: { required },
    question9: { required },
    question10: { required },
  },
}
</script>

<style lang="scss" scoped>
.testimonial__desc {
  margin-bottom: 30px;
}
.testimonial__input {
  margin-bottom: 20px;
}

.testimonial__btn {
  background: transparent;
  border: 1px solid #53284f;
  border-radius: 50px;
  color: #53284f;
  padding: 4px 15px;
}

label {
  font-size: 16px;
  text-align: left;
  display: block;
}
input {
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px;
  background: #efefef;
}

textarea {
  width: 100%;
  border-radius: 5px;
  background: #efefef;
  margin-top: 10px;
}

.testimonial__input-error {
  text-align: left;
  font-size: 12px;
  color: #e91e63;
}

.question {
  margin-bottom: 15px;
}

.answers {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.answer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.error {
  margin-bottom: 20px;
  color: #e91e63;
}
</style>
