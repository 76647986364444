<template>
  <div class="cart wrapper">
    <HomeLink />
    <div v-if="showError" class="error">
      {{ $t(errorText) }}
    </div>
    <div class="wrapper__title">{{ $t("title") }}</div>
    <div class="cart__items" v-if="cartItems.length">
      <div class="cart__address" v-if="roomNumber">
        <div>{{ $t("yourNumberIs", [roomNumber]) }}</div>
        <!--<a href="#" @click="clearRoomNumber">{{ $t("changeNumber") }}</a>-->
        <div class="cart__options">
          <div class="cart__options-title">{{ $t('selectDelivery') }}</div>
          <div class="cart__options-list">
            <Select class="cart__select" :list="deliveryList" :placeholder="$t('select')" @input="(value) => deliveryType = value"></Select>
            <Select v-if="deliveryType === 'requestedDeliveryTime'" class="cart__select" :list="deliveryTimeList" :placeholder="$t('selectTime')" @input="(value) => deliveryTime = value"></Select>
          </div>
        </div>
        <div class="cart__options">
          <div class="cart__options-title">{{ $t('selectPayment') }}</div>
          <div class="cart__options-list">
            <Select class="cart__select" :list="paymentList" :placeholder="$t('select')" @input="(value) => paymentType = value"></Select>
          </div>
        </div>
        <div class="cart__options">
          <textarea v-model="comment" class="cart__comment" :placeholder="$t('comment')"></textarea>
        </div>
      </div>
      <div class="cart__item-type" v-if="restaurantItems.length">
        <div class="cart___subtitle">
          {{ $t("restaurant") }}
        </div>
        <div class="cart__table-titles">
          {{ $t("sumSubtotal") }}
        </div>
        <div class="cart__items">
          <div
            class="cart__item"
            v-for="item in restaurantItems"
            :key="item.title_ru"
          >
            <div class="cart__item-title">
              {{
                currentLanguage === "ru"
                  ? item.title_ru
                  : item.title_en || item.title_ru
              }}
            </div>
            <div class="cart__item-price" v-if="item.price">
              {{ item.price }} c
            </div>
            <div class="cart__item-subtotal">
              {{ item.subtotal ? item.subtotal + " c" : $t("free") }}
            </div>
            <div class="cart__item-qty qty">
              <input
                @click="changeQty('discrease', item)"
                type="button"
                value="-"
                class="button-minus"
                data-field="quantity"
              />
              <input
                type="number"
                step="1"
                max=""
                disabled
                :value="item.qty"
                name="quantity"
                class="quantity-field"
              />
              <input
                @click="changeQty('increase', item)"
                type="button"
                value="+"
                class="button-plus"
                data-field="quantity"
              />
            </div>
            <div class="cart__item-del">
              <button @click="del(item)">{{ $t("delete") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cart__item-type" v-if="roomServiceItems.length">
        <div class="cart___subtitle">
          {{ $t("roomService") }}
        </div>
        <div class="cart__table-titles">
          {{ $t("sumSubtotal") }}
        </div>
        <div class="cart__items">
          <div
            class="cart__item"
            v-for="item in roomServiceItems"
            :key="item.title_ru"
          >
            <div class="cart__item-title">
              {{
                currentLanguage === "ru"
                  ? item.title_ru
                  : item.title_en || item.title_ru
              }}
            </div>
            <div class="cart__item-price" v-if="item.price">
              {{ item.price }} c
            </div>
            <div class="cart__item-subtotal">
              {{ item.subtotal ? item.subtotal + " c" : $t("free") }}
            </div>
            <div class="cart__item-qty qty">
              <input
                @click="changeQty('discrease', item)"
                type="button"
                value="-"
                class="button-minus"
                data-field="quantity"
              />
              <input
                type="number"
                step="1"
                max=""
                disabled
                :value="item.qty"
                name="quantity"
                class="quantity-field"
              />
              <input
                @click="changeQty('increase', item)"
                type="button"
                value="+"
                class="button-plus"
                data-field="quantity"
              />
            </div>
            <div class="cart__item-del">
              <button @click="del(item)">{{ $t("delete") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cart__item-type" v-if="thingsItems.length">
        <div class="cart___subtitle">
          {{ $t("requestItems") }}
        </div>
        <div class="cart__table-titles">
          {{ $t("sumSubtotal") }}
        </div>
        <div class="cart__items">
          <div
            class="cart__item"
            v-for="item in thingsItems"
            :key="item.title_ru"
          >
            <div class="cart__item-title">
              {{
                currentLanguage === "ru"
                  ? item.title_ru
                  : item.title_en || item.title_ru
              }}
            </div>
            <div class="cart__item-price" v-if="item.price">
              {{ item.price }} c
            </div>
            <div class="cart__item-subtotal">
              {{ item.subtotal ? item.subtotal + " c" : $t("free") }}
            </div>
            <div class="cart__item-qty qty">
              <input
                @click="changeQty('discrease', item)"
                type="button"
                value="-"
                class="button-minus"
                data-field="quantity"
              />
              <input
                type="number"
                step="1"
                max=""
                disabled
                :value="item.qty"
                name="quantity"
                class="quantity-field"
              />
              <input
                @click="changeQty('increase', item)"
                type="button"
                value="+"
                class="button-plus"
                data-field="quantity"
              />
            </div>
            <div>
            </div>
            <div class="cart__item-del">
              <button @click="del(item)">{{ $t("delete") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cart__total">{{ $t("totalPrice") }} {{ totalPrice }} c</div>
      <button class="cart__submit" @click="submitOrder()">
        {{ $t("sendOrder") }}
      </button>
    </div>

    <div v-else>
      <div v-if="success" class="success-sent">
        {{ $t("successSent") }}
        <router-link :to="{ name: 'home' }">{{ $t("toHome") }}</router-link>
      </div>
      <div v-else>{{ $t("cartIsEmpty") }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HomeLink from "@/components/common/HomeLink";
import Select from '@/components/form/Select';
export default {
  data() {
    return {
      restaurantItems: null,
      roomServiceItems: null,
      thingsItems: null,
      success: false,
      deliveryType: '',
      deliveryTime: '',
      comment: '',
      paymentType: '',
      showError: false,
      errorText: '',
      deliveryList: [
        {
          id: 'asSoonAsReady',
          value: this.$t('asSoonAsReady'),
        },
        {
          id: 'requestedDeliveryTime',
          value: this.$t('requestedDeliveryTime'),
        },
      ],
      paymentList: [
        {
          id: 'roomCharge',
          value: this.$t('roomCharge'),
        },
        {
          id: 'cash',
          value: this.$t('cash'),
        },
        {
          id: 'card',
          value: this.$t('card'),
        },
      ],
      deliveryTimeList: [
        {
          id: '7:00',
          value: '7:00',
        },
        {
          id: '8:00',
          value: '8:00',
        },
        {
          id: '9:00',
          value: '9:00',
        },
                {
          id: '10:00',
          value: '10:00',
        },
        {
          id: '11:00',
          value: '11:00',
        },
        {
          id: '12:00',
          value: '12:00',
        },
                {
          id: '13:00',
          value: '13:00',
        },
        {
          id: '14:00',
          value: '14:00',
        },
        {
          id: '15:00',
          value: '15:00',
        },
                {
          id: '16:00',
          value: '16:00',
        },
        {
          id: '17:00',
          value: '17:00',
        },
        {
          id: '18:00',
          value: '18:00',
        },
      ],
    };
  },
  i18n: {
    messages: {
      ru: {
        title: "Ваша корзина",
        yourNumberIs: "Доставим заказ в номер №{0}",
        changeNumber: "Сменить номер",
        restaurant: "Ресторан",
        sumSubtotal: "Стоимость / Подытог",
        delete: "Удалить",
        cartIsEmpty: "Корзина пуста.",
        successSent:
          "Благодарим за Ваш заказ и желаем Вам приятного Аппетита!",
        toHome: "На главную страницу",
        totalPrice: "Общая сумма",
        sendOrder: "Оформить заказ",
        roomService: "Рум-Сервис",
        requestItems: "Предметы по заказу",
        free: "Бесплатно",
        time: 'Время заказа',
        asSoonAsReady: 'Ближайшее',
        requestedDeliveryTime: 'Ко времени',
        selectDelivery: 'Время доставки',
        select: 'Выберите',
        selectPayment: 'Способ оплаты',
        selectTime: 'Выберите время',
        roomCharge: 'Записать на номер  ',
        cash: 'Оплатить наличными',
        card: 'Оплатить картой',
        selectDeliveryType: 'Пожалуйста, выберите метод доставки.',
        selectDeliveryTime: 'Пожалуйста, выберите время доставки.',
        selectPaymentType: 'Пожалуйста, выберите метод оплаты.',
        comment: 'Пожалуйста, поделитесь своими диетическими требованиями или любыми другими запросами, связанными с вашим заказом.',
      },
      en: {
        title: "Your Cart",
        yourNumberIs: "Your Room Number is #{0}",
        changeNumber: "Change Number",
        restaurant: "Restaurant",
        sumSubtotal: "Price / Subtotal",
        delete: "Delete",
        cartIsEmpty: "Your Cart is Empty.",
        successSent:
          "Thank you for dining with us! Bon Appetit!",
        toHome: "Goto Home",
        totalPrice: "Total Price",
        sendOrder: "Send Order",
        roomService: "Room Service",
        requestItems: "Request Items",
        free: "Free",
        time: 'Time',
        asSoonAsReady: 'As soon as ready',
        requestedDeliveryTime: 'Requested Delivery Time',
        selectDelivery: 'Delivery Time',
        select: 'Select',
        selectPayment: 'Payment',
        selectTime: 'Select Time',
        roomCharge: 'Room Charge',
        cash: 'Cash',
        card: 'Card',
        selectDeliveryType: 'Please select delivery method.',
        selectDeliveryTime: 'Please select delivery time.',
        selectPaymentType: 'Please select payment time.',
        comment: 'Please share you dietary requirements or any others requests related to your order.',
      },
    },
  },
  components: {
    HomeLink,
    Select,
  },
  computed: {
    ...mapState(["cartItems", "roomNumber", "currentLanguage"]),
    normlizedItems() {
      return this.cartItems.map((i) => {});
    },
    totalPrice() {
      return this.cartItems.reduce((total, item) => {
        return (total += item.subtotal);
      }, 0);
    },
  },
  methods: {
    changeQty(mode, item) {
      if (mode === "discrease" && item.qty === 1) return;
      const newItem =
        mode === "increase"
          ? { ...item, qty: item.qty + 1 }
          : { ...item, qty: item.qty - 1 };

      this.$store.dispatch("changeQty", newItem);
    },
    del(item) {
      this.$store.dispatch("deleteFromCart", item);
    },
    breakIntoPieces() {
      if (this.cartItems) {
        this.restaurantItems = this.cartItems.filter((item) => {
          return item.itemType === "restaurant";
        });
        this.roomServiceItems = this.cartItems.filter((item) => {
          return item.itemType === "room_service";
        });
        this.thingsItems = this.cartItems.filter((item) => {
          return item.itemType === "things";
        });
      }
    },
    clearRoomNumber() {
      this.$store.dispatch("clearRoomNumber");
    },
    async submitOrder() {

      if (this.deliveryType === '') {
        this.setError('selectDeliveryType');
        return;
      }

      if (this.deliveryType == 'requestedDeliveryTime' && this.deliveryTime === '') {
        this.setError('selectDeliveryTime');
        return;
      }

      if (this.paymentType === '') {
        this.setError('selectPaymentType');
        return;
      }

      await this.$store.dispatch("submitOrder", {paymentType: this.paymentType, deliveryType: this.deliveryType, deliveryTime: this.deliveryTime, comment: this.comment});
      this.success = true;
    },
    setError (text) {
      this.showError = true;
      this.errorText = text;
      setTimeout(() => {
        this.showError = false;
        this.errorText = '';
      }, 5000)
    }
  },
  watch: {
    cartItems() {
      this.breakIntoPieces();
    },
  },
  created() {
    this.breakIntoPieces();
  },
};
</script>

<style lang="scss" scoped>

.error {
  position: fixed;
  right: 20px;
  top: 100px;
  background: red;
  padding: 5px 15px;
  border-radius: 3px;
  color: white;
  height: 34px;
}
.cart {
  &___subtitle {
    text-align: left;
    font-weight: bold;
  }

  &__select {
    margin-top: 5px;
  }

  &__options {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__options-list {
    display: flex;
    gap: 10px;
  }

  &__options-title {
    font-size: 14px;
  }

  &__table-titles {
    text-align: right;
    font-size: 10px;
  }

  &__total {
    text-align: right;
    margin-top: 20px;
  }

  &__time {
    display: block;
    margin-top: 10px;
  }

  &__comment {
    width: 100%;
    border-radius: 7px;

    &::placeholder {
      font-size: 14px;
    }
  }

  &__address {
    text-align: left;
    margin-bottom: 25px;

    a {
      color: #53284f;
      border-bottom: 1px dashed;
      text-decoration: none;
      font-size: 13px;
    }
  }

  &__item-type {
    margin-bottom: 25px;
    background: white;
    /* box-shadow: 0 11px 16px rgb(165 165 165 / 21%); */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%); */
    border: 1px solid bisque;
  }

  &__submit {
    background: transparent;
    border: 1px solid #53284f;
    border-radius: 50px;
    color: #53284f;
    padding: 4px 15px;
    margin-top: 30px;
  }
}

.cart__item {
  display: grid;
  gap: 5px;
  grid-template-areas:
    "title price subtotal"
    "qty qty del";
  grid-template-columns: 1fr minmax(50px, 100px) max-content;
  border-bottom: 1px solid bisque;
  padding-bottom: 15px;
  padding-top: 15px;

  &:last-child {
    border-bottom: none;
  }

  &-title {
    grid-area: title;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
  }

  &-price {
    grid-area: price;
    text-align: right;
    align-items: center;
    display: grid;
  }

  &-subtotal {
    grid-area: subtotal;
    text-align: right;
    align-items: center;
    display: grid;
  }

  &-qty {
    grid-area: qty;
    grid-column: 2/3;
    justify-self: end;
  }

  &-del {
    grid-area: del;
    grid-column: 3/4;
    text-align: right;
    button {
      grid-area: button;
      border: 1px solid #53284f;
      text-decoration: none;
      border-radius: 20px;
      color: #53284f;
      background: transparent;
      font-size: 13px;
      margin-left: 15px;
    }
  }
}
</style>
