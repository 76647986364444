<template>

  <div class="travel container">

    <HomeLink />

    <div class="wrapper__title">{{ $t("title") }}</div>

    <div class="travel__title">{{ $t("oimoTashTitle") }}</div>

    <div>{{ $t("oimoTashDesc") }}</div>

    <div class="travel__images" v-viewer>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/1.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/2.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/3.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/4.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/5.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/6.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/7.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/8.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/oimophotos/9.jpeg" />

      </div>

    </div>

    <br />

    <div class="travel__title">{{ $t("skyfallTitle") }}</div>

    <div>{{ $t("skyfallDesc") }}</div>

    <div class="travel__images" v-viewer>

      <div>

        <img src="https://orient.smng.kg/uploads/skyfall/1.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/skyfall/2.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/skyfall/3.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/skyfall/4.jpeg" />

      </div>

      <div>

        <img src="https://orient.smng.kg/uploads/skyfall/5.jpeg" />

      </div>

    </div>

    <br />

    <div class="travel__title">{{ $t("contactInfo") }}</div>

    <div class="travel__contact">

      <span>E-mail</span>

      <a href="mailto:oimotash.kg@gmail.com ">oimotash.kg@gmail.com</a>

    </div>

    <div class="travel__contact">

      <span>Telegram</span>

      <a target="_blank" href="https://t.me/oimotash">
         +996555432444 (@oimotash)
      </a>

    </div>

    <div class="travel__contact">

      <span>Whatsapp</span>

      <a target="_blank" href="https://wa.me/+996555432444">+996555432444</a>

    </div>

    <div class="travel__contact">

      <span>Instagram</span>

      <a target="_blank" href="https://instagram.com/orient_travel_kg">
         @orient_travel_kg
      </a>

      <a target="_blank" href="https://instagram.com/oimotashkg">
         @oimotashkg
      </a>

      <a target="_blank" href="https://instagram.com/skyfall_kg">
         @skyfall_kg
      </a>

    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";
import HomeLink from "@/components/common/HomeLink";
import ServicesItem from "@/components/ui/ServicesItem";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);
export default {
  components: {
    HomeLink,
    ServicesItem,
    Viewer,
  },
  data() {
    return {
      openedAccordion: 0,
    };
  },
  computed: {
    ...mapState(["currentLanguage", "items"]),
  },
  async created() {
    try {
      await this.$store.dispatch("getItems", "qr_services");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    toggleService(value) {
      this.openedAccordion = value;
    },
  },
  i18n: {
    messages: {
      ru: {
        contactInfo: "Контактная информация",
        title: "Путешествие вместе с «Orient Travel»",
        welcomeText: "",
        oimoTashTitle: "Этно-комплекс Оймо-Таш",
        oimoTashDesc:
          "Бескрайние просторы, где на километры ни души, чистейший воздух и оглушающая тишина. Место для семейного отдыха и полной перезагрузки, где вы почувствуете жизнь, энергию и силу! Своеобразная эко деревня из белоснежных юрт и каменных дорог – это символы прошлой жизни кыргызов. Посетив этно-комплекс «ОймоТаш», Вы сможете приблизиться к культуре кыргызов - пожить в настоящей юрте кочевого народа. Мы заботимся о том, чтобы наши гости провели незабываемый отдых, сочетающий в себе невысокую стоимость проживания, необычную обстановку, комфорт, приветливый персонал и удачное расположение!",
        skyfallTitle: "Мини-отель «Skyfall»",
        skyfallDesc:
          "Мини отель Скайфол расположен в живописном месте на севере Иссык Куля ,в 250 метрах от берега ,тихий и уютный отдых ,комфортабельные номера так же наш мини отель удобен для коллективного отдыха .",
      },
      en: {
        contactInfo: "Contact Information",
        title: "Infromation",
        welcomeText: "",
        title: "Travel with «Orient Travel»",
        oimoTashTitle: "Oimo Tash Ethno Yurt Complex",
        oimoTashDesc:
          "Бескрайние просторы, где на километры ни души, чистейший воздух и оглушающая тишина. Место для семейного отдыха и полной перезагрузки, где вы почувствуете жизнь, энергию и силу! Своеобразная эко деревня из белоснежных юрт и каменных дорог – это символы прошлой жизни кыргызов. Посетив этно-комплекс «ОймоТаш», Вы сможете приблизиться к культуре кыргызов - пожить в настоящей юрте кочевого народа. Мы заботимся о том, чтобы наши гости провели незабываемый отдых, сочетающий в себе невысокую стоимость проживания, необычную обстановку, комфорт, приветливый персонал и удачное расположение!",
        skyfallTitle: "«Skyfall» Mini Hotel",
        skyfallDesc:
          "Мини отель Скайфол расположен в живописном месте на севере Иссык Куля ,в 250 метрах от берега ,тихий и уютный отдых ,комфортабельные номера так же наш мини отель удобен для коллективного отдыха .",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}

.travel {
  margin-top: 25px;
  &__title {
    font-weight: bold;
    margin: 10px;
  }

  &__contact {
    text-align: left;
    margin-bottom: 10px;

    a {
      color: #53294f;
      display: block;
    }

    span {
      display: block;
      font-size: 13px;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;

    > div {
      position: relative;
      width: 100%;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
      }
    }
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}
</style>

