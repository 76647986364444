<template>

  <div class="restaurant wrapper">

    <HomeLink />

    <div class="wrapper__title">{{ $t("title") }}</div>
     Смотреть отдел «Меню ресторана»
  </div>

</template>

<script>
import views from "@/mixins/views";
import HomeLink from "@/components/common/HomeLink";
import { mapState } from "vuex";
export default {
  mixins: [views],
  components: {
    HomeLink,
  },
  async created() {
    try {
      await this.$store.dispatch("getItems", "room_service");
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapState(["items"]),
  },
  i18n: {
    messages: {
      ru: {
        title: "Меню Рум-Сервис",
      },
      en: {
        title: "Room Service Menu",
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>

