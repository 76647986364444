<template>
  <div class="restaurant wrapper">
    <div class="container">
      <HomeLink />
      <div class="wrapper__title">{{ $t("title") }}</div>
      <!---<div v-for="n in 20" :key="n">
        <img :src="'https://khantengri.kg/uploads/menu/menu' + n + '.jpg'">
      </div>-->
      <div v-if="categories.length" class="categories">
        <CategoryItem v-for="(category, idx) in categories" :key="idx" :item="category"></CategoryItem>
      </div>
    </div>
  </div>
</template>

<script>
import views from "@/mixins/views";
import HomeLink from "@/components/common/HomeLink";
import CategoryItem from '@/components/ui/CategoryItem'

export default {
  mixins: [views],
  components: {
    HomeLink,
    CategoryItem
  },
  async created() {
    try {
      await this.$store.dispatch("getCategories", "restaurant");
    } catch (error) {
      console.log(error);
    }
  },
  i18n: {
    messages: {
      ru: {
        title: "Меню ресторана",
        restaurantMenuLink: 'Ссылка на меню ресторана',
      },
      en: {
        title: "Restaurant Menu",
        restaurantMenuLink: 'Restaurant Menu Link',
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
