<template>
  <div>
    <router-link :to="{ name: 'home' }"
      ><span class="icon-back"></span> {{ $t("home") }}</router-link
    >
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      ru: {
        home: "На главную",
      },
      en: {
        home: "Home",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-size: 13px;
  text-align: left;
  display: block;
  color: #53284f;
  text-decoration: none;
  margin-bottom: 15px;
}

.icon-back {
  font-size: 10px;
}
</style>
