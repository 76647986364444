<template>
  <div class="select">
    <div @click="expand = !expand" class="select__selected" :class="{'select__selected--value': selected}">{{ selected.value || placeholder }} </div>
    <div v-if="expand" class="select__list">
      <div class="select__item" v-for="item in list" @click="select(item)">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      selected: '',
      expand: false,
    }
  },
  methods: {
    select (item) {
      this.selected = item
      this.$emit('input', item.id)
      this.expand = false
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  &__selected {
    color: #296dcb;
    text-decoration: underline;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;

    &--value {
      color: #53284f;
    }
  }

  &__list {
    position: absolute;
    background: white;
    border: 1px solid bisque;
    padding: 10px;
    border-radius: 10px;
    top: 25px;
    z-index: 10;
    width: 200px;
  }

  &__item {
    font-size: 14px;
    cursor: pointer;
  }
}
</style>