<template>
  <div class="information wrapper">
    <HomeLink />
    <div class="wrapper__title">{{ $t("title") }}</div>
    <div>{{ $t("welcomeText") }}</div>
    <br />
    <ServicesItem
      v-for="item in items"
      :item="item"
      :key="item.id"
      :openedId="openedAccordion"
      @toggleService="toggleService"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import HomeLink from "@/components/common/HomeLink"
import ServicesItem from "@/components/ui/ServicesItem"
export default {
  components: {
    HomeLink,
    ServicesItem,
  },
  data() {
    return {
      openedAccordion: 0,
    }
  },
  computed: {
    ...mapState(["currentLanguage", "items"]),
  },
  async created() {
    try {
      await this.$store.dispatch("getItems", "qr_services")
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    toggleService(value) {
      this.openedAccordion = value
    },
  },
  i18n: {
    messages: {
      ru: {
        title: "Общая информация",
        welcomeText: "Добро пожаловать в комплекс Orient Complex!",
      },
      en: {
        title: "Infromation",
        welcomeText: "Welcome to Orient Complex!",
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
