<template>

  <div class="category" @click="openCategory(3)">

    <ImageUi v-if="image" :imageUrl="image"></ImageUi>

    <div v-else>

      <img src="@/assets/noimage.jpeg" />

    </div>

    <p>{{ title }}</p>

  </div>

</template>

<script>
import { eventBus } from "@/main";
import { mapState } from "vuex";
import ImageUi from "@/components/ui/Image";

export default {
  name: "CategoryItem",
  props: {
    item: Object,
  },
  components: {
    ImageUi,
  },
  computed: {
    image() {
      if (this.item.image) {
        return (
          "https://orient.smng.kg/" +
          "uploads/" +
          this.item.image.replace("|", "thumbnail/")
        );
      }
      return false;
    },
    ...mapState(["currentLanguage"]),
    title() {
      return this.currentLanguage === "ru"
        ? this.item.title_ru
        : this.item.title_en || this.item.title_ru;
    },
  },
  methods: {
    openCategory(id) {
      eventBus.$emit("menu", {
        id: this.item.id,
        type: "restaurant",
        title: this.title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  cursor: pointer;
  // min-width: 200px;
  // min-height: 200px;
  justify-content: center;
  img {
    object-fit: cover;
    border-radius: 8px;
    margin: auto;
  }

  p {
    font-weight: bold;
    margin-top: 5px;
    text-align: center;
  }
}
</style>

